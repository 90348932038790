import { useRef, useState } from 'react';
import './timeregistration-date-range-filter.css';
import Tabs from '../../tabs/tabs';
import InputField from '../../input-field/input-field';
import Button from '../../button/button';
import { currentWeek } from '../../../utils/currentWeek';

// Function to show a list of years based on the current year back to 2024
const yearsToSelect = Array.from(
  { length: new Date().getFullYear() - 2024 + 1 },
  (_, i) => new Date().getFullYear() - i
).map((year) => ({
  value: year.toString(),
  label: year.toString(),
}));

const getWeeksInYear = () => {
  const weeks = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Find the first Monday of the year
  const firstDayOfYear = new Date(currentYear, 0, 1);
  while (firstDayOfYear.getDay() !== 1) {
    firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
  }

  // Generate weeks up to the current week
  for (
    let weekNumber = 1;
    weekNumber <= currentWeek(currentDate);
    weekNumber++
  ) {
    weeks.push({
      value: weekNumber.toString(),
      label: weekNumber.toString(),
    });
  }

  return weeks;
};

const getMonthsInYear = () => {
  const months = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Find the first Monday of the year
  const firstDayOfYear = new Date(currentYear, 0, 1);
  while (firstDayOfYear.getDay() !== 1) {
    firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
  }

  // Generate months of the year, starting from January to December
  for (let monthNumber = 0; monthNumber < 12; monthNumber++) {
    const label = new Date(currentYear, monthNumber, 1).toLocaleString(
      'da-DK',
      {
        month: 'long',
      }
    );

    months.push({
      value: monthNumber.toString(),
      label: label.charAt(0).toUpperCase() + label.slice(1),
    });
  }

  return months;
};

function TimeRegistrationDateRangeFilter({
  value,
  onChange,
  valueToDisplay,
  setValueToDisplay,
}) {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Måned');

  const [startDate, setStartDate] = useState(new Date(value.startDate));
  const [endDate, setEndDate] = useState(new Date(value.endDate));

  const [tempStartDate, setTempStartDate] = useState(new Date(value.startDate));
  const [tempEndDate, setTempEndDate] = useState(new Date(value.endDate));

  const intervalStartDateRef = useRef(null);
  const intervalEndDateRef = useRef(null);

  const handleWeekChange = (e) => {
    const { value } = e.target;

    if (value === '') {
      return;
    }

    const weekNumber = parseInt(value, 10);

    // Calculate the start date of the selected week
    const firstMondayOfYear = new Date(Date.UTC(startDate.getFullYear(), 0, 1));
    while (firstMondayOfYear.getUTCDay() !== 1) {
      firstMondayOfYear.setUTCDate(firstMondayOfYear.getUTCDate() + 1);
    }

    const newStartDate = new Date(firstMondayOfYear);
    newStartDate.setUTCDate(newStartDate.getUTCDate() + (weekNumber - 1) * 7);
    newStartDate.setUTCHours(0, 0, 0, 0);

    const newEndDate = new Date(newStartDate);
    newEndDate.setUTCDate(newEndDate.getUTCDate() + 6);
    newEndDate.setUTCHours(23, 59, 59, 999);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onChange({
      startDate: newStartDate.toISOString().split('T')[0],
      endDate: newEndDate.toISOString().split('T')[0],
    });

    setValueToDisplay(`Uge ${weekNumber}`);
  };

  const handleMonthChange = (e) => {
    const { value } = e.target;

    if (value === '') {
      return;
    }

    const newStartDate = new Date(
      Date.UTC(startDate.getFullYear(), parseInt(value), 1)
    );
    newStartDate.setUTCHours(0, 0, 0, 0);

    const newEndDate = new Date(
      Date.UTC(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 1)
    );
    newEndDate.setUTCHours(23, 59, 59, 999);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onChange({
      startDate: newStartDate.toISOString().split('T')[0],
      endDate: newEndDate.toISOString().split('T')[0],
    });

    setValueToDisplay(
      newStartDate.toLocaleDateString('da-DK', {
        month: 'long',
        year: 'numeric',
      })
    );
  };

  const handleYearChange = (e) => {
    const { value } = e.target;
    if (value === '') {
      return;
    }

    const newYear = parseInt(value, 10);

    // Find the first Monday of the year
    const firstMondayOfYear = new Date(Date.UTC(newYear, 0, 1));

    // Find the first Monday of the year if it is not a Monday, as the first week of the year is the week containing the first Monday
    // This is done to ensure that the first week of the year is not split between two years
    while (firstMondayOfYear.getUTCDay() !== 1) {
      firstMondayOfYear.setUTCDate(firstMondayOfYear.getUTCDate() + 1);
    }

    const newStartDate = new Date(firstMondayOfYear);
    const newEndDate = new Date(newStartDate);
    // Set the end date to the end of the year
    newEndDate.setUTCFullYear(newEndDate.getUTCFullYear() + 1);
    newEndDate.setUTCMonth(0);
    newEndDate.setUTCDate(1);
    newEndDate.setUTCHours(0, 0, 0, 0);
    // Subtract one day to get the last day of the previous year
    newEndDate.setUTCDate(newEndDate.getUTCDate() - 1);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onChange({
      startDate: newStartDate.toISOString().split('T')[0],
      endDate: newEndDate.toISOString().split('T')[0],
    });

    setValueToDisplay(newYear.toString());
  };

  const handleIntervalChange = (e) => {
    const { name, value } = e.target;

    if (name === 'from') {
      setTempStartDate(new Date(value));
    } else if (name === 'to') {
      setTempEndDate(new Date(value));
    }
  };

  const handleIntervalChangeSubmit = () => {
    // Check if the start date is before the end date
    if (tempStartDate > tempEndDate) {
      return;
    }

    const newStartDate = new Date(
      Date.UTC(
        tempStartDate.getFullYear(),
        tempStartDate.getMonth(),
        tempStartDate.getDate()
      )
    );
    newStartDate.setUTCHours(0, 0, 0, 0);

    const newEndDate = new Date(
      Date.UTC(
        tempEndDate.getFullYear(),
        tempEndDate.getMonth(),
        tempEndDate.getDate()
      )
    );
    newEndDate.setUTCHours(23, 59, 59, 999);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onChange({
      startDate: newStartDate.toISOString().split('T')[0],
      endDate: newEndDate.toISOString().split('T')[0],
    });

    setValueToDisplay(
      `${newStartDate.toLocaleDateString('da-DK', {
        timeZone: 'UTC',
      })} - ${newEndDate.toLocaleDateString('da-DK', { timeZone: 'UTC' })}`
    );
  };

  return (
    <div className="timeregistration-date-range-filter">
      <div
        className={`timeregistration-date-range-filter-trigger ${
          open ? 'timeregistration-date-range-filter-trigger--open' : ''
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="timeregistration-date-range-filter-trigger-label">
          <label>Tidsperiode</label>
        </div>
        <div className="timeregistration-date-range-filter-trigger-value">
          {valueToDisplay}
        </div>
      </div>
      <div
        className={`timeregistration-date-range-filter-popout ${
          open ? 'timeregistration-date-range-filter-popout--open' : ''
        }`}
      >
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            { label: 'Uge' },
            { label: 'Måned' },
            { label: 'År' },
            { label: 'Interval' },
          ]}
        />
        <div className="timeregistration-date-range-filter-popout-content">
          {activeTab === 'Uge' && (
            <InputField
              name="week"
              label="Uge"
              type="select"
              placeholder="- Vælg -"
              onChange={handleWeekChange}
              options={getWeeksInYear()}
            />
          )}

          {activeTab === 'Måned' && (
            <InputField
              name="month"
              label="Måned"
              type="select"
              placeholder="- Vælg -"
              onChange={handleMonthChange}
              options={getMonthsInYear()}
            />
          )}

          {activeTab === 'År' && (
            <InputField
              name="year"
              label="År"
              type="select"
              placeholder="- Vælg -"
              onChange={handleYearChange}
              options={yearsToSelect}
            />
          )}

          {activeTab === 'Interval' && (
            <div className="timeregistration-date-range-filter-intervals">
              <div className="timeregistration-date-range-filter-intervals__inner">
                <InputField
                  className="timeregistration-date-range-filter-intervals__input"
                  name="from"
                  label="Fra"
                  type="date"
                  placeholder="dd.mm.åååå"
                  value={tempStartDate}
                  onChange={handleIntervalChange}
                  forwardRef={intervalStartDateRef}
                />
                <InputField
                  className="timeregistration-date-range-filter-intervals__input"
                  name="to"
                  label="Til"
                  type="date"
                  placeholder="dd.mm.åååå"
                  value={tempEndDate}
                  onChange={handleIntervalChange}
                  forwardRef={intervalEndDateRef}
                />
              </div>
              <Button onClick={handleIntervalChangeSubmit} className="small">
                Anvend
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`timeregistration-date-range-filter-overlay ${
          open ? 'timeregistration-date-range-filter-overlay--open' : ''
        }`}
        onClick={() => setOpen(false)}
      ></div>
    </div>
  );
}

export default TimeRegistrationDateRangeFilter;
