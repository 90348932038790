import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panels, Panel } from '../../components/panel/panel';
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  Timestamp,
  arrayUnion,
  arrayRemove,
  collection,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import Avatar from '../../components/avatar/avatar';
import { db, storage } from '../../firebase-config';
import { UserInfoContext, EmployeesContext } from '../../context';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import { useForm } from '../../hooks/useForm';
import Modal from '../../components/modal/modal';
import './agreements-detail-view.css';
import InputField from '../../components/input-field/input-field';
import FileUpload from '../../components/file-upload/file-upload';
import AGREEMENT_TYPE from '../../constants/fields/agreementType';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as ics from 'ics';

const uploadFiles = async (files, companyId, agreementId, onUploadComplete) => {
  // Check for early return conditions
  if (!files || !companyId || !agreementId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  const fileDataArray = await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/agreements/${agreementId}/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const fileData = {
        date: Timestamp.fromDate(new Date()),
        extension: file.name.split('.').pop(),
        filePath: fileUrl,
        name: file.name,
      };

      const agreementDoc = doc(db, 'agreements', agreementId);
      await updateDoc(agreementDoc, {
        files: arrayUnion(fileData),
      });

      return fileData;
    })
  );

  if (onUploadComplete && typeof onUploadComplete === 'function') {
    onUploadComplete(fileDataArray);
  }
  return true;
};

export default function AgreementsDetailView() {
  const isInitialLoad = useRef(true);
  const { agreementId } = useParams();
  const [agreementDetailData, setAgreementDetailData] = useState({});
  const [regarding, setRegarding] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [notesSaveButtonEnabled, setNotesSaveButtonEnabled] = useState(false);
  const [notesSaveFeedback, setNotesSaveFeedback] = useState('');
  const [summarySaveButtonEnabled, setSummarySaveButtonEnabled] =
    useState(false);
  const [formFeedback, setFormFeedback] = useState('');
  const [summarySaveFeedback, setSummarySaveFeedback] = useState('');
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);

  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [showFollowUpDateManual, setShowFollowUpDateManual] = useState(false);
  const [manualFollowUpDate, setManualFollowUpDate] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate();

  const deleteFile = async (file) => {
    const fileRef = ref(storage, file.filePath);
    await deleteObject(fileRef);

    // Remove the file from the agreement
    const agreementDoc = doc(db, 'agreements', agreementId);
    await updateDoc(agreementDoc, {
      files: arrayRemove(file),
    });

    // Remove the file from the state
    setAttachments((prevAttachments) =>
      prevAttachments.filter(
        (attachment) => attachment.filePath !== file.filePath
      )
    );

    return true;
  };

  const handleFilesUploaded = useCallback((newFiles) => {
    setAttachments((prevAttachments) => [...prevAttachments, ...newFiles]);
  }, []);

  const handleSummaryChange = (e) => {
    const { name, value } = e.target;
    setAgreementDetailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Increase the height of the textarea as the user types
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 10 + 'px';

    // If the summary is different, enable the save button
    if (value !== agreementDetailData.summary) {
      setSummarySaveButtonEnabled(true);
      setSummarySaveFeedback('');
    } else {
      setSummarySaveButtonEnabled(false);
    }
  };

  const handleSummarySave = async () => {
    const agreementDoc = doc(db, 'agreements', agreementId);
    await updateDoc(agreementDoc, {
      summary: agreementDetailData.summary,
    });

    setSummarySaveFeedback('Beskrivelse er gemt.');
    setSummarySaveButtonEnabled(false);
  };

  const handleNotesChange = (e) => {
    const { name, value } = e.target;
    setAgreementDetailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // If the notes are different, enable the save button
    if (value !== agreementDetailData.notes) {
      setNotesSaveButtonEnabled(true);
      setNotesSaveFeedback('');
    } else {
      setNotesSaveButtonEnabled(false);
    }
  };

  const handleNotesSave = async () => {
    const agreementDoc = doc(db, 'agreements', agreementId);
    await updateDoc(agreementDoc, {
      notes: agreementDetailData.notes,
    });

    setNotesSaveFeedback('Noterne er gemt');
    setNotesSaveButtonEnabled(false);
  };

  const handleEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleFollowUpDateChange = (e) => {
    const { value } = e.target;
    if (value === 'Vælg dato') {
      setShowFollowUpDateManual(true);
      setSelectedFrequency(value); // Indicate that the user selected the custom date option
    } else {
      setShowFollowUpDateManual(false);
      const daysToAdd = parseInt(value, 10);
      // Ensure you're using the agreement's current date as the base date
      const baseDate = new Date(agreementDetailData.date.toDate()); // Convert Firestore Timestamp to Date if needed
      baseDate.setDate(baseDate.getDate() + daysToAdd);
      setFollowUpDate(baseDate.toISOString().split('T')[0]); // Format for input[type=date]
      setSelectedFrequency(value); // Track the selected frequency value
    }
  };

  const handleFollowUpDateChangeManual = (e) => {
    setManualFollowUpDate(e.target.value);
  };

  const toggleMeetingStatus = async () => {
    // Check if we have agreement data and a document ID
    const agreementDocRef = doc(db, 'agreements', agreementId);
    try {
      // Update the document in Firestore
      await setDoc(
        agreementDocRef,
        {
          isClosed: !isClosed,
        },
        { merge: true }
      );

      setIsClosed(!isClosed);

      // Display follow-up modal if the agreement type has a frequency and the isClosed is false
      if (
        !isClosed &&
        AGREEMENT_TYPE.find((type) => type.value === values.agreementType)
          .frequencyOptions.length > 0
      ) {
        setIsFollowUpModalOpen(true);
      } else {
        navigate('/aftaler');
      }
    } catch (error) {
      // Handle any errors
      setFormFeedback('Der skete en fejl. Prøv igen senere.');
      console.error('Error updating agreement:', error);
    }
  };

  const handleBookFollowUp = async () => {
    // Use the manual follow-up date if it's selected, otherwise use the calculated follow-up date
    const finalFollowUpDate = showFollowUpDateManual
      ? manualFollowUpDate
      : followUpDate;

    const utcFollowUpDate = finalFollowUpDate
      ? new Date(finalFollowUpDate)
      : null;

    const valuesToSave = {
      ...values,
      regarding: doc(db, 'users', values.regarding),
      attendees: values.attendees.map((attendeeId) =>
        doc(db, 'users', attendeeId)
      ),
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      isClosed: false,
      date: utcFollowUpDate ? Timestamp.fromDate(utcFollowUpDate) : null,
    };

    const newAgreementRef = collection(db, 'agreements');

    try {
      // Add a new document with a generated id.
      await addDoc(newAgreementRef, valuesToSave);

      // Redirect to the newly created agreement
      navigate(`/aftaler`);
    } catch (error) {
      // Handle any errors
      setFormFeedback('Der skete en fejl. Prøv igen senere.');
      console.error('Error updating agreement:', error);
    }
  };

  const handleCalendarFileDownload = () => {
    // Convert from JS Date to an array of integers
    function formatDateForICS(date) {
      return date
        .toISOString()
        .split(/[-:T]/)
        .map((a) => parseInt(a))
        .slice(0, 6);
    }

    ics.createEvent(
      {
        start: formatDateForICS(values.date),
        location: values?.location || '',
        duration: { hours: 1 },
        startInputType: 'utc',
        status: 'CONFIRMED',
        description: agreementDetailData.summary || '',
        title: `${values.title} vedrørende ${regarding.data.firstName} ${regarding.data.lastName}`,
        organizer: {
          name: `${userInfo.firstName} ${userInfo.lastName}`,
          email: userInfo.email,
        },
      },
      (error, value) => {
        if (error) {
          console.error(error);
          return;
        }
        const blob = new Blob([value], { type: 'text/calendar' });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${values.title} vedrørende ${regarding.data.firstName} ${regarding.data.lastName}.ics`;
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Revoke the URL to free up memory
        window.URL.revokeObjectURL(url);
      }
    );
  };

  // Handle opening and closing the delete modal
  function toggleDeleteModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  // Handle deleting the agreement
  async function handleDeleteAgreement() {
    const agreementDocRef = doc(db, 'agreements', agreementId);
    try {
      await deleteDoc(agreementDocRef);
      navigate('/aftaler');
    } catch (error) {
      console.error('Error deleting agreement:', error);
    }
  }

  // Form validation
  const validateAgreementForm = (values) => {
    let errors = {};

    if (!values.title.trim()) errors.title = 'Titel mangler';
    if (!values.date) errors.date = 'Dato mangler';

    return errors;
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      const valuesToSave = {
        ...values,
        date: values.date ? Timestamp.fromDate(new Date(values.date)) : null,
        regarding: doc(db, 'users', values.regarding),
        attendees: values.attendees.map((attendeeId) =>
          doc(db, 'users', attendeeId)
        ),
      };

      const agreementDocRef = doc(db, 'agreements', agreementId);

      try {
        await setDoc(agreementDocRef, valuesToSave, { merge: true });
        setIsEditModalOpen(false);

        const regarding = employees.find((emp) => emp.id === values.regarding);

        if (regarding) {
          setRegarding({
            id: regarding.id,
            data: regarding.data,
          });
        }

        const attendeesData = values.attendees.map((attendee) => {
          const employee = employees.find((emp) => emp.id === attendee);

          if (!employee) return null;

          return {
            id: employee.id,
            firstName: employee.data.firstName,
            lastName: employee.data.lastName,
            profilePhotoUrl: employee.data.profilePhotoUrl,
          };
        });

        setAttendees(attendeesData);
      } catch (error) {
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error updating agreement:', error);
      }
    },
    [agreementId, employees]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      title: '',
      location: '',
      date: '',
      isClosed: false,
      regarding: '',
      attendees: [],
      agreementType: '',
    },
    validateAgreementForm,
    onSubmit
  );

  // Fetch agreement data
  useEffect(() => {
    const fetchAgreementData = async () => {
      // Return early if the values are already set
      if (values.title) return;

      const agreementDocRef = doc(db, 'agreements', agreementId);
      try {
        const docSnap = await getDoc(agreementDocRef);
        if (docSnap.exists()) {
          let data = docSnap.data();

          // Find the details of the person this is regarding from the employees context
          const regarding = employees.find(
            (emp) => emp.id === data.regarding.id
          );

          if (!regarding) {
            // Return early if the regarding person is not found
            return;
          }

          setRegarding({
            id: regarding.id,
            data: regarding.data,
          });

          // Check if all attendee IDs exist in the loaded employees
          const allAttendeesLoaded = data.attendees.every((attendee) =>
            employees.some((emp) => emp.id === attendee.id)
          );

          if (!allAttendeesLoaded) {
            return; // Exit if not all attendees are loaded
          }

          // Set the attendees and find their details from the employees context
          const attendeesData = data.attendees.map((attendee) => {
            const employee = employees.find((emp) => emp.id === attendee.id);

            if (!employee) return null;

            return {
              id: employee.id,
              firstName: employee.data.firstName,
              lastName: employee.data.lastName,
              profilePhotoUrl: employee.data.profilePhotoUrl,
            };
          });

          setAttendees(attendeesData);
          setAgreementDetailData(data);

          const agreementTypeFrequencyDefault = AGREEMENT_TYPE.find(
            (type) => type.value === data.agreementType
          )?.frequencyOptions.find((option) => option.default)?.value;

          // This will set the selected frequency in the dropdown
          if (agreementTypeFrequencyDefault !== undefined) {
            setSelectedFrequency(agreementTypeFrequencyDefault.toString());

            // Additionally, calculate and set the follow-up date based on this default frequency
            const currentDate = data.date
              ? new Date(data.date.toDate())
              : new Date(); // Assuming `data.date` is a Firestore Timestamp
            currentDate.setDate(
              currentDate.getDate() + agreementTypeFrequencyDefault
            );
            setFollowUpDate(currentDate);
          }

          setIsClosed(data.isClosed);

          setValues({
            title: data.title,
            location: data.location,
            // Convert the Firestore Timestamp to a string
            date: data.date ? data.date.toDate() : '',
            isClosed: data.isClosed,
            regarding: data.regarding.id,
            attendees: data.attendees.map((attendee) => attendee.id),
            agreementType: data.agreementType,
          });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        // Set loading to false
        isInitialLoad.current = false;
      }
    };

    fetchAgreementData();
  }, [agreementId, employees, setValues, values.title]);

  // Fetch attachments
  useEffect(() => {
    if (agreementDetailData.files && agreementDetailData.files.length > 0) {
      setAttachments(agreementDetailData.files);
    }
  }, [agreementDetailData.files]);

  return (
    <div className="main-layout">
      <PageTop
        title={
          isInitialLoad.current ? (
            <Skeleton width={700} />
          ) : (
            <div className="agreement-details-title">
              <span className="agreement-details-title__headline">
                {values.title}
              </span>
              <h4>
                <span className="agreement-details-title__regarding">
                  Vedrørende:
                </span>
                {regarding?.data?.firstName} {regarding?.data?.lastName}
              </h4>
            </div>
          )
        }
      />
      <Panels>
        <Panel columns="65" noPadding>
          <div className="agreement-details">
            {isInitialLoad.current ? (
              <>
                <Skeleton width={400} count={2} height={23} />
                <Skeleton width={400} count={1} height={32} />
                <Skeleton width={400} count={1} height={32} />
              </>
            ) : (
              <>
                <div className="agreement-details-row">
                  <span className="agreement-details-row__label">Lokation</span>
                  <div className="agreement-details-row__value">
                    {values.location}
                  </div>
                </div>
                <div className="agreement-details-row">
                  <span className="agreement-details-row__label">Status</span>
                  <div className="agreement-details-row__value">
                    {values.isClosed ? 'Afsluttet' : 'Åben'}
                  </div>
                </div>
                <div className="agreement-details-row agreement-details-row--date">
                  <span className="agreement-details-row__label">Dato</span>
                  <div className="agreement-details-row__value">
                    <div className="agreement-details-row__value__date">
                      {values.date &&
                        new Date(values.date).toLocaleString('da-DK', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}

                      {!values.isClosed &&
                        values.date &&
                        new Date(values.date).getTime() <
                          new Date().getTime() && (
                          <span className="warning">
                            <Icon name="warning" /> Forfalden
                          </span>
                        )}
                    </div>
                    <div className="agreement-details-row__value__actions">
                      <Button
                        buttonStyle="text-link"
                        onClick={handleCalendarFileDownload}
                        style={{ marginTop: '1rem' }}
                      >
                        Tilføj til din kalender
                      </Button>
                      <small
                        style={{
                          display: 'block',
                          marginTop: '16px',
                        }}
                      >
                        {' '}
                        (husk at invitere deltagere i din kalender)
                      </small>
                    </div>
                  </div>
                </div>
                <div className="agreement-details-row agreement-details-row--regarding">
                  <span className="agreement-details-row__label">
                    Vedrørende
                  </span>
                  <div className="agreement-details-row__value">
                    <Link
                      key={regarding.id}
                      className="agreement-details-row-attendees-row"
                      to={`/medarbejdere/${regarding.id}`}
                    >
                      <Avatar
                        key={regarding.id}
                        firstName={regarding?.data?.firstName}
                        lastName={regarding?.data?.lastName}
                        profilePhotoUrl={regarding?.data?.profilePhotoUrl}
                        size="small"
                      />
                      <span>
                        {regarding?.data?.firstName} {regarding?.data?.lastName}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="agreement-details-row agreement-details-row--attendees">
                  <span className="agreement-details-row__label">
                    Deltagere
                  </span>
                  <div className="agreement-details-row__value">
                    <div className="agreement-details-row-attendees">
                      {attendees.length > 0 &&
                        attendees.map((attendee) => {
                          if (!attendee) return null;

                          return (
                            <Link
                              key={attendee.id}
                              className="agreement-details-row-attendees-row"
                              to={`/medarbejdere/${attendee.id}`}
                            >
                              <Avatar
                                key={attendee.id}
                                firstName={attendee.firstName}
                                lastName={attendee.lastName}
                                profilePhotoUrl={attendee.profilePhotoUrl}
                                size="small"
                              />
                              <span>
                                {attendee.firstName} {attendee.lastName}
                              </span>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="agreement-details-row agreement-details-row--summary">
                  <span className="agreement-details-row__label">
                    Beskrivelse
                  </span>
                  <div className="agreement-details-row__value">
                    <InputField
                      name="summary"
                      value={agreementDetailData.summary}
                      type="textarea"
                      onChange={handleSummaryChange}
                      disabled={isClosed}
                    />
                    <small>
                      Beskrivelsen kommer med hvis aftalen tilføjes til
                      kalenderen.
                    </small>
                    {!isClosed && (
                      <div className="agreement-summary-actions">
                        {summarySaveFeedback && (
                          <span className="agreement-summary-actions__feedback">
                            {summarySaveFeedback}
                          </span>
                        )}

                        <Button
                          disabled={!summarySaveButtonEnabled}
                          onClick={handleSummarySave}
                          buttonStyle="secondary"
                          className="small"
                        >
                          Gem beskrivelse
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="agreement-details-actions">
                  {!isClosed && (
                    <Button
                      buttonStyle="text-link"
                      className="small"
                      onClick={handleEditModalToggle}
                    >
                      Ret detaljer
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="agreement-notes">
            {isInitialLoad.current ? (
              <>
                <Skeleton width={260} count={1} height={36} />
                <Skeleton count={1} height={240} />
              </>
            ) : (
              <>
                <h4>Noter</h4>
                <InputField
                  name="notes"
                  value={agreementDetailData.notes}
                  type="textarea"
                  style={{ minHeight: '200px' }}
                  onFocus={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                  }}
                  onChange={handleNotesChange}
                  disabled={isClosed}
                />
                {!isClosed && (
                  <div className="agreement-notes-actions">
                    {notesSaveFeedback && (
                      <span className="agreement-notes-actions__feedback">
                        {notesSaveFeedback}
                      </span>
                    )}
                    <Button
                      disabled={!notesSaveButtonEnabled}
                      onClick={handleNotesSave}
                      buttonStyle="secondary"
                      className="small"
                    >
                      Gem noter
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Panel>
        <Panel noPadding columns="35">
          {isInitialLoad.current ? (
            <div className="agreement-attachments">
              <Skeleton height={50} count={2} />
            </div>
          ) : (
            <>
              <div className="agreement-attachments">
                <h4>Vedhæftede filer</h4>
                {attachments.length > 0 && (
                  <div className="agreement-attachments-files">
                    {attachments.map((file, index) => {
                      return (
                        <div className="agreement-attachments-file" key={index}>
                          <Icon name="description" />
                          <Link
                            to={file.filePath}
                            target="_blank"
                            className="agreement-attachments-file-information"
                          >
                            {file.name}
                            <span>
                              Uploaded{' '}
                              {file.date &&
                                file.date.toDate().toLocaleString('da-DK', {
                                  dateStyle: 'short',
                                  timeStyle: 'short',
                                })}
                            </span>
                          </Link>
                          {!isClosed && (
                            <div
                              className="agreement-attachments-file-remove"
                              onClick={() => deleteFile(file)}
                            >
                              <Icon name="close" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isClosed && (
                  <FileUpload
                    handleUpload={(files) =>
                      uploadFiles(
                        files,
                        userInfo.companyId,
                        agreementId,
                        handleFilesUploaded
                      )
                    }
                  />
                )}
              </div>

              <div className="agreement-actions">
                <Button onClick={toggleMeetingStatus}>
                  {isClosed ? 'Genåben' : 'Afslut'}
                </Button>
              </div>
            </>
          )}
        </Panel>
        <Panel noPadding columns={65}>
          <div className="action-list">
            <div className="action-list-item">
              <div className="action-list-item__description">
                <h5>Slet denne aftale</h5>
                <p>
                  Når aftalen er slettet, kan det ikke laves om. Vær sikker i
                  din beslutning.
                </p>
              </div>
              <div className="action-list-item__action">
                <Button
                  type="button"
                  buttonStyle="danger"
                  onClick={toggleDeleteModal}
                >
                  Slet aftale
                </Button>
                <Modal
                  isModalOpen={isDeleteModalOpen}
                  setIsModalOpen={setIsDeleteModalOpen}
                  header={`Slet aftale`}
                  bottom={
                    <>
                      <Button
                        buttonStyle="danger"
                        onClick={handleDeleteAgreement}
                      >
                        Slet aftale
                      </Button>
                    </>
                  }
                  content={
                    <div className="delete-modal-content">
                      <Icon name="warning" />
                      <p>Er du sikker på at du vil slette aftalen?</p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </Panel>
      </Panels>
      <Modal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        header={`Ret detaljer`}
        bottom={
          <>
            {formFeedback && (
              <span className="agreement-edit-modal-feedback">
                {formFeedback}
              </span>
            )}
            <Button onClick={handleSubmit}>Gem ændringer</Button>
          </>
        }
        content={
          <div className="agreement-edit-modal-content">
            <InputField
              required
              label="Titel"
              name="title"
              value={values.title}
              onChange={handleChange}
              placeholder="Titel"
              error={errors.title}
              help={`Titlen kommer med hvis aftalen tilføjes til kalenderen.`}
            />
            <InputField
              label="Lokation"
              name="location"
              value={values.location}
              onChange={handleChange}
              placeholder="Lokation"
            />
            <InputField
              required
              label="Dato"
              name="date"
              type="datetime"
              datePickerPosition="bottom"
              datePickerFixed={true}
              value={values.date}
              onChange={handleChange}
              placeholder="Dato"
              error={errors.date}
            />
            <InputField
              required
              label="Vedrørende"
              name="regarding"
              type="select2"
              value={
                employees.find((employee) => employee.id === values.regarding)
                  ? {
                      value: values.regarding,
                      label:
                        employees.find(
                          (employee) => employee.id === values.regarding
                        )?.data?.firstName +
                        ' ' +
                        employees.find(
                          (employee) => employee.id === values.regarding
                        )?.data?.lastName,
                    }
                  : null
              }
              onChange={handleChange}
              options={
                employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                  target: {
                    value: employee.id,
                    name: 'regarding',
                  },
                })) || []
              }
              error={errors.regarding}
            />
            <InputField
              label="Deltagere"
              name="attendees"
              type="select2"
              value={values.attendees.map((attendeeId) => {
                const employee = employees.find((e) => e.id === attendeeId);
                return {
                  value: attendeeId,
                  label: employee
                    ? `${employee.data.firstName} ${employee.data.lastName}`
                    : '',
                  target: {
                    value: attendeeId,
                    name: 'attendees',
                  },
                };
              })}
              onChange={(selectedOptions) => {
                // Create an event array or single event object based on selection
                const event = selectedOptions.map((option) => ({
                  target: {
                    name: 'attendees',
                    value: option.value,
                  },
                }));
                // Call handleChange with the constructed event and explicit field name
                handleChange(event.length > 0 ? event : [], 'attendees');
              }}
              isMulti
              options={employees.map((employee) => ({
                value: employee.id,
                label: `${employee.data.firstName} ${employee.data.lastName}`,
                target: {
                  value: employee.id,
                  name: 'attendees',
                },
              }))}
            />
          </div>
        }
      />
      <Modal
        isModalOpen={isFollowUpModalOpen}
        setIsModalOpen={setIsFollowUpModalOpen}
        header={`Book follow-up?`}
        bottom={
          <>
            {formFeedback && (
              <span className="agreement-edit-modal-feedback">
                {formFeedback}
              </span>
            )}
            <Button onClick={() => handleBookFollowUp()}>
              Opret follow-up
            </Button>
          </>
        }
        content={
          <>
            <p>
              Vil du booke et follow-up <strong>{values.agreementType}</strong>{' '}
              møde til
            </p>
            <InputField
              type="select"
              label="Hvornår?"
              name="followUpDate"
              value={selectedFrequency}
              options={[
                ...(AGREEMENT_TYPE.find(
                  (type) => type.value === values.agreementType
                )?.frequencyOptions || []),
                { label: 'Vælg dato', value: 'Vælg dato' },
              ]}
              onChange={handleFollowUpDateChange}
              placeholder="- Vælg -"
            />
            {showFollowUpDateManual && (
              <InputField
                type="datetime"
                label="Dato"
                name="followUpDateManual"
                value={manualFollowUpDate}
                onChange={handleFollowUpDateChangeManual}
                datePickerFixed={true}
                placeholder="Dato"
              />
            )}
          </>
        }
      />
    </div>
  );
}
